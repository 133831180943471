#heading{
    margin-top: 80px;
    margin-left: 10px;
    color: white;
    font-size: 30px;
    font-weight: bolder;
}
.JustforGrid{
    margin: 10px;
    display: grid;
    grid-template-columns: repeat(5,1fr);
    gap: 20px;
}
.images{
    border-radius: 5px;
    transition: transform 450ms;
    cursor: pointer;
}
.images:hover{
    transform: scale(1.2);
}
@media screen and (min-width:0px) and (max-width:900px){
    .JustforGrid{
        grid-template-columns: repeat(4,1fr);
        gap: 15px;
    }
}
@media screen and (min-width:0px) and (max-width:600px){
    .JustforGrid{
        grid-template-columns: repeat(3,1fr);
        gap: 10px;
        margin: 5px;
    }#heading{
        font-size: 25px;
        margin-left: 5px;
    }
    .images:hover{
        transform: scale(1.1);
    }
    .paginationbtn{
        margin-bottom:80px;
    }
}@media screen and (min-width:0px) and (max-width:400px){
    .JustforGrid{
        grid-template-columns: repeat(2,1fr);
        gap: 5px;
        margin: 5px;
    }
    #heading{
        font-size: 15px;
    }
}