.tab-bar{
    display:none;
}
.hero{
    height: auto;
    position: absolute;
    width: 100vw;
    background-color: #000000ed;
    color: white;
    padding: 30px 80px 30px 30px;
    box-shadow: rgb(0 0 0) 0px -10px 50px 30px;
}
.hero .container{
    justify-content: space-between;
    flex-wrap: wrap;
    flex: 1;
}
.flex{
    display: flex;
}
.container__about{
    margin-bottom: 30px;
}
.container__about p{
    margin-top: 10px;
    max-width: 300px;
    color: rgba(255, 255, 255,0.6);
    font-size: 14px;
}
.container-pages{
    column-gap: 30px;
}
.container-pages ul{
    padding-top: 10px;
    list-style: none;
    line-height: 24px;
}
.container-pages ul a{
    color: inherit;
    text-decoration: none;
    color: rgba(255, 255, 255,0.6);
    font-size: 14px;
}
.container-pagesul a:hover{
    color: aqua;
}
footer .by{
    margin-top: 30px;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
footer .by p{
    color: rgba(255, 255, 255,0.6);
    font-size: 14px;
}
.line_separete{
    width: 100%;
    z-index: 999;
}
.icons{
    margin-top: 20px;
    display: flex;
    column-gap: 25px;
}
.icon1{
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    justify-content: center;
    font-size: 20px;
    color: white;
    border-radius: 50%;
    outline: 2px solid white;
}
.icon1:hover{
    outline-offset: 4px;
}
.icon--instagram:hover{
    background-color:rgb(255, 71, 102);
    outline-color: #ffffff;
}
.icon--twitter:hover{
    background-color: #ff0000;
    outline-color: #ffffff;
}
.icon--linkedin:hover{
    background-color: #0077b5;
    outline-color: #ffffff;
}
.icon--github:hover{
    background-color: #000;
    outline-color: #ffffff;
}
@media only screen and (max-width:800px){
    .hero{
        padding: 10px;
        display: flex;
        flex-direction: column;
    }
    .container__about h2{
        font-size: 20px;
    }
    .container__about p{
        font-size: 12px;
    }
    .container__recentpages h2{
        font-size: 20px;
    }
    .container-pages ul a{
        font-size: 12px;
    }
    footer .by a{
        font-size: 12px;
    }
}
@media only screen and (max-width:650px){
    .hero{
        display: none;
    }
    .tab-bar{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #000000d6;
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 60px;
        padding: 0 16px;
        box-shadow: -3px -9px 20px 0px rgba(0, 0, 0, 1);
    }
    .tab-item{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        background-color: transparent;
        cursor: pointer;
    }
    .tab-item span{
        font-size: 12px;
        font-weight: bold;
        margin-top: 4px;
        color: white;
    }
    .tab-item svg{
        width: 24px;
        height: 24px;
        color: white;
    }
}