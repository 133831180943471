#h1{
    margin-top: 80px;
    font-size: 30px;
    font-weight: bolder;
    margin-left: 30px;
    color: white;
    margin-bottom: 50px;
}
#h1>span{
    color: red;
    font-family: Alkatra;
    font-weight: bolder;
}
#h2{
    font-size: 20px;
    color: white;
    margin-left: 30px;
    font-weight: bolder;
}
#h2>span{
    text-decoration: underline;
}
#h2>a{
    color: rgb(255, 114, 114);
    font-family: Alkatra;
    font-weight: bolder;
}